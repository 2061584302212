var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-header"},[_c('div',{staticClass:"title-1 new-01"},[_vm._v("Spotlights")]),_c('div',{staticClass:"user-icons newicons"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"slider-tap",attrs:{"src":require("../assets/switch-button.svg")}})])],1)]),_c('div',{staticClass:"content-slider"},_vm._l((_vm.spotLightGames),function(spotLightGame){return _c('div',{key:spotLightGame.app_id,staticClass:"slider-01"},[_c('div',{staticClass:"slider-main-cont"},[_c('div',{staticClass:"pl-coin"},[_c('ul',[_vm._m(0,true),_c('li',[_vm._v(_vm._s(spotLightGame.charge_coin))])])]),_c('div',{staticClass:"titles inner-slider-pg"},[_vm._v(_vm._s(spotLightGame.title))]),_c('div',{staticClass:"slider-img"},[_c('router-link',{attrs:{"to":{
              name: 'GameDetail',
              params: { gameId: spotLightGame.app_id }
            }}},[_c('img',{attrs:{"src":_vm.host + spotLightGame.thumbnail_path}})])],1),_c('div',{staticClass:"cat-description-slider"},[_vm._v(" "+_vm._s(spotLightGame.long_description.substr(0, 20))+"... ")]),_c('p',[_vm._v(_vm._s(spotLightGame.avgRating ? spotLightGame.avgRating : "5.0"))]),_c('div',{staticClass:"rating-box"},[_c('div',{staticClass:"rating",style:({
              width: spotLightGame.rating
                ? spotLightGame.rating + '%'
                : '100%'
            })})]),_c('div',{staticClass:"subtitles"},[_vm._v(" "+_vm._s(spotLightGame.count)+" "+_vm._s(spotLightGame.count == 1 ? "Player" : "Players")+" ")]),_c('div',{staticClass:"redeem-gift"},[_c('router-link',{attrs:{"to":{
              name: 'GameDetail',
              params: { gameId: spotLightGame.app_id }
            }}},[_vm._v(" Start Game ")])],1)])])}),0),_c('div',{staticClass:"top-category"},[_c('div',{staticClass:"title-section"}),_c('div',{staticClass:"cat-btn"},[_c('div',{staticClass:"cat-nm-1",class:{ active: _vm.activeCategory === 'all' }},[_c('a',{on:{"click":function($event){return _vm.gameCategoryList('all')}}},[_c('img',{attrs:{"src":require("../assets/arcade.svg")}}),_vm._v(" All ")])]),_vm._l((_vm.gameCategory),function(category){return _c('div',{key:category,staticClass:"cat-nm-1",class:{ active: _vm.activeCategory === category }},[_c('a',{on:{"click":function($event){return _vm.gameCategoryList(category)}}},[_c('img',{attrs:{"src":require("../assets/tournaments.svg")}}),_vm._v(_vm._s(category)+" ")])])})],2)]),_c('div',{staticClass:"single-games"},[_c('ul',{staticClass:"games-box"},_vm._l((_vm.gameList),function(game){return _c('li',{key:game.app_id},[_c('router-link',{attrs:{"to":{ name: 'GameDetail', params: { gameId: game.app_id } }}},[_c('img',{attrs:{"src":_vm.host + game.thumbnail_path}}),_c('span',[_vm._v(" "+_vm._s(game.title))]),_c('div',{staticClass:"cat-1"},[_vm._v(" "+_vm._s(game.long_description.substr(0, 20))+"... ")])])],1)}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../assets/gold-coins-icon.svg")}})])
}]

export { render, staticRenderFns }