<template>
  <div>
    <div class="content-header">
      <div class="title-1 new-01">Spotlights</div>
      <div class="user-icons newicons">
        <router-link to="/">
          <img src="../assets/switch-button.svg" class="slider-tap" />
        </router-link>
      </div>
    </div>

    <div class="content-slider">
      <div
        class="slider-01"
        v-for="spotLightGame in spotLightGames"
        :key="spotLightGame.app_id"
      >
        <div class="slider-main-cont">
          <div class="pl-coin">
            <ul>
              <li><img src="../assets/gold-coins-icon.svg" /></li>
              <li>{{ spotLightGame.charge_coin }}</li>
            </ul>
          </div>
          <div class="titles inner-slider-pg">{{ spotLightGame.title }}</div>

          <div class="slider-img">
            <router-link
              :to="{
                name: 'GameDetail',
                params: { gameId: spotLightGame.app_id }
              }"
            >
              <img :src="host + spotLightGame.thumbnail_path" />
            </router-link>
          </div>
          <div class="cat-description-slider">
            {{ spotLightGame.long_description.substr(0, 20) }}...
          </div>
          <p>{{ spotLightGame.avgRating ? spotLightGame.avgRating : "5.0" }}</p>
          <div class="rating-box">
            <div
              class="rating"
              :style="{
                width: spotLightGame.rating
                  ? spotLightGame.rating + '%'
                  : '100%'
              }"
            ></div>
          </div>
          <div class="subtitles">
            {{ spotLightGame.count }}
            {{ spotLightGame.count == 1 ? "Player" : "Players" }}
          </div>

          <div class="redeem-gift">
            <!--          <a href="#"> Start Game</a>-->
            <router-link
              :to="{
                name: 'GameDetail',
                params: { gameId: spotLightGame.app_id }
              }"
            >
              Start Game
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="top-category">
      <div class="title-section"></div>

      <div class="cat-btn">
        <div
          class="cat-nm-1"
          v-bind:class="{ active: activeCategory === 'all' }"
        >
          <a @click="gameCategoryList('all')">
            <img src="../assets/arcade.svg" /> All
          </a>
        </div>
        <div
          class="cat-nm-1"
          v-for="category in gameCategory"
          :key="category"
          v-bind:class="{ active: activeCategory === category }"
        >
          <a @click="gameCategoryList(category)">
            <img src="../assets/tournaments.svg" />{{ category }}
          </a>
        </div>
      </div>
    </div>
    <div class="single-games">
      <ul class="games-box">
        <li v-for="game in gameList" :key="game.app_id">
          <router-link
            :to="{ name: 'GameDetail', params: { gameId: game.app_id } }"
          >
            <img :src="host + game.thumbnail_path" />
            <span> {{ game.title }}</span>
            <div class="cat-1">
              {{ game.long_description.substr(0, 20) }}...
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SliderMode",
  data: () => {
    return {
      gameList: [],
      spotLightGames: [],
      gameCategory: [],
      searchData: [],
      isLogin: 0,
      host: null,
      loadMore: true,
      activeCategory: "all",
      starTotal: 5,
      msisdn: localStorage.getItem("phone")
        ? localStorage.getItem("phone")
        : "empty"
    };
  },
  created() {
    this.host = this.$root.url();
    let url = this.host;
    axios
      .get(url)
      .then(response => {
        this.gameList = response.data.allGames;
        this.searchData = this.gameList;
        this.spotLightGames = response.data.spotLightGames;
        this.gameCategory = response.data.gameCategory;
        this.isLogin = response.data.isLogin;
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    shortDescription: function(str) {
      let result = str;
      let resultArray = result.split(" ");
      if (resultArray.length > 12) {
        resultArray = resultArray.slice(0, 12);
        result = resultArray.join(" ") + "…";
      }
      return result;
    },
    getGameList: function() {
      alert("scrolling");
    },
    imageStatus(msisdn = "empty") {
      console.log(msisdn);
      this.$router.push({ name: "Home" });
    },
    gameCategoryList(category) {
      this.activeCategory = category;
      let findData = [];
      if (category !== "all") {
        findData = this.searchData.filter(
          game => game.catagory_name === category
        );
      } else {
        findData = this.searchData;
      }
      this.gameList = findData;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rating-box {
  color: #f68127;
}

.rating-box:before {
  content: "\f006 \f006 \f006 \f006 \f006";
}

.rating-box .rating {
  color: #f68127;
}

.rating-box .rating:before {
  content: "\f005 \f005 \f005 \f005 \f005";
}
</style>
